
export class FomoPaymentResponse{
  public id: string;  
  public orderNo: string;   
  public mode: string;
  public subject: string;
  public description: string;
  public returnUrl: string;
  public backUrl: string;
  public notifyUrl: string;
  public currencyCode: string;
  public amount: string;
  public createdAt: number;
  public status: string;
  public url: string;

  public tokenOrderId: string; 

}
