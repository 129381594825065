import { Component, ViewChild, Inject, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { EmployeeSchedule } from 'src/app/models/employee-schedule.model';
import { InstitutionService } from 'src/app/services/institution.service';
import { Utilities } from 'src/app/services/utilities';
import { Institution } from 'src/app/models/institution.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EmployeeScheduleService } from 'src/app/services/employee-schedule.service';
import { Subscription } from 'rxjs';
import { EmployeeScheduleShift } from '../../models/employee-schedule-shift.model';
import { LocationService } from '../../services/location.service';
import { Location } from '../../models/location.model';
import { LocType } from 'src/app/models/enums';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'employee-schedule-editor',
  templateUrl: './employee-schedule-editor.component.html',
  styleUrls: ['./employee-schedule-editor.component.css'],
  animations: [fadeInOut]
})
export class EmployeeScheduleEditorComponent implements OnInit, OnDestroy{
  private subscription: Subscription = new Subscription();
  private isSaving: boolean;
  private showValidationErrors: boolean = true;
  public formResetToggle = true;
  private loadingIndicator = false;
  private isNewEmployeeSchedule = false;
  private originalEmployeeSchedule: EmployeeSchedule = new EmployeeSchedule();
  private employeeScheduleEdit: EmployeeSchedule = new EmployeeSchedule();
  private allInstitutions: Institution[] = [];
  private validation = { code: false, label: false};
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  public locationSearchForm: FormControl = new FormControl();

  @ViewChild('f')
  private form;

  @ViewChild('code')
  private code;

  @ViewChild('label')
  private label;
    allLocations: Location[];
  selected: any;

  buildingList: Location[];

  constructor(private alertService: AlertService, private accountService: AccountService, private institutionService: InstitutionService,
    private employeeScheduleService: EmployeeScheduleService, private locationService: LocationService,
    public dialogRef: MatDialogRef<EmployeeScheduleEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (typeof (data.employeeSchedule) != typeof (undefined)) {
      if (data.employeeSchedule.id) {
        this.editEmployeeSchedule(data.employeeSchedule);
      } else {
        this.newEmployeeSchedule();
      }
    }

    this.locationService.getLocationsByInstitutionId(this.accountService.currentUser.institutionId)
        .subscribe(results => {
          this.allLocations = results;

          var rooms = results.filter(f => f.locationTypeName == LocType.Room);
          this.buildingList = [];
          var bList = {};
          rooms.forEach(function (loc, i, array) {
            if (loc.parentLocation != null && loc.parentLocation.locationTypeName == LocType.Building) {
              if (!bList.hasOwnProperty(loc.parentLocation.id)) {
                bList[loc.parentLocation.id] = loc.parentLocation;
              }
            }
          });

          var keys = Object.keys(bList);
          this.buildingList = keys.map(function (v) { return bList[v]; });
        },
          error => {
            
          });
  }

  getRoomsByBuildingId(id) {
    let unsortedLocations = this.allLocations.filter(f => f.parentLocationId == id)
    unsortedLocations.sort((a, b) => (a.name < b.name ? -1 : 1));

    return unsortedLocations;
  }

  addShift() {
    if (!this.employeeScheduleEdit.shifts) this.employeeScheduleEdit.shifts = [];

    let s = new EmployeeScheduleShift();
    s.isActive = 1;

    this.employeeScheduleEdit.shifts.push(s);
  }

  addLocation(selected: any) {
    if (!selected) return;
    if (!this.employeeScheduleEdit.locations) this.employeeScheduleEdit.locations = [];

    if (this.employeeScheduleEdit.locations.some(e => e.locationId === selected.id)) {
      this.alertService.showStickyMessage("Error", "Location is already added", MessageSeverity.error);
      return;
    }

    let c: any = {};
    Object.assign(c, selected);
    c.isActive = 1;

    c.locationId = c.id;
    c.id = "0";
    this.employeeScheduleEdit.locations.push(c);

    console.log('sLoc', this.employeeScheduleEdit.locations);

    this.selected = null;
  }

  ngOnInit() {
    this.alertService.resetStickyMessage();
  }

  ngOnDestroy() {
    this.alertService.resetStickyMessage();
    this.subscription.unsubscribe();
  }

  private save() {
      this.isSaving = true;
      this.alertService.startLoadingMessage("Saving changes...");


      if (!this.employeeScheduleEdit.id) {
        this.employeeScheduleService.newEmployeeSchedule(this.employeeScheduleEdit).subscribe(employeeSchedule => this.saveSuccessHelper(employeeSchedule), error => this.saveFailedHelper(error));
      }
      else {
        this.employeeScheduleService.updateEmployeeSchedule(this.employeeScheduleEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
      }
  }

  private saveSuccessHelper(employeeSchedule?: EmployeeSchedule) {
    if (employeeSchedule)
      Object.assign(this.employeeScheduleEdit, employeeSchedule);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewEmployeeSchedule) {
      this.alertService.showMessage("Success", `Data \"${this.employeeScheduleEdit.code}\" was created successfully`, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage("Success", `Changes to data \"${this.employeeScheduleEdit.code}\" was saved successfully`, MessageSeverity.success);
    }

    if (this.changesSavedCallback)
      this.changesSavedCallback();

    this.dialogRef.close();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured while saving your changes:", MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //if (this.changesFailedCallback)
    //  this.changesFailedCallback();

    //this.dialogRef.close();
  }


  private cancel() {
    this.employeeScheduleEdit = new EmployeeSchedule();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback)
      this.changesCancelledCallback();

    this.dialogRef.close();
  }

  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  loadInstitutions() {
    this.institutionService.getInstitutions()
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.allInstitutions = results[0];

      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Load Error", `Unable to retrieve institutions from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error);
        });
  }

  newEmployeeSchedule() {
    this.showValidationErrors = true;
    this.isNewEmployeeSchedule = true;
    this.employeeScheduleEdit = new EmployeeSchedule();
    return this.employeeScheduleEdit;
  }

  editEmployeeSchedule(employeeSchedule: EmployeeSchedule) {
    if (employeeSchedule) {
      this.isNewEmployeeSchedule = false;
      this.showValidationErrors = true;
      this.originalEmployeeSchedule = employeeSchedule;
      this.employeeScheduleEdit = new EmployeeSchedule();
      Object.assign(this.employeeScheduleEdit, employeeSchedule);

      return this.employeeScheduleEdit;
    }
    else {
      return this.newEmployeeSchedule();
    }
  }

  get canManageEmployeeSchedules() {
    return this.accountService.userHasPermission(Permission.manageEmployeeSchedulePermission);
  }

}
