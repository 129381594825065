
export class DirectoryListing {
  public id: string;
  public code: string;
  public label: string;


  public description: string;

  public extraField: string;
  public iconUrl: string;
  public availableForBooking: boolean;

  public isInternal: boolean;

  public aliases: string;
  public disciplines: string;

  public contact: string;

  public unitNumber: string;

  public highlightIcon: string;
  public highlightText: string;
  public pause: boolean;
  public pauseTime: number;

  public mapDisplayName: string;

  public directoryListingId: string;
  public directoryListingCode: string;
  public directoryListingLabel: string;

  public floorId: string;
  public floorLabel: string;

  public pointId: string;
  public isActive: boolean;
}
