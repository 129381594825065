
export class BentoAsset {


  public id: string;
  public code: string;
  public institutionId: string;

  public bentoBoxTypeId: string; 

  public bentoBoxTypeCode: string;

  public cartonAssetId: string;

  public cartonAssetCode: string;

  public dishId: string;

  public dishCode: string;

  public date: Date;

  public timeStamp: Date;
}
