import { QueueTableMap } from "./queue-table-map.model";

export class QueueTableMapGroup {
  

  public queueId: string;
  public roomName: string;

  public list: QueueTableMap[];
    devices: any;
}
