import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';


@Component({
    selector: 'facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.css'],
    animations: [fadeInOut]
})
export class FacilitiesComponent {

}
