
export class LineModel {
  public id: string;
  public code: string;
  public label: string;

  public mapId: string;

  public point0Id: string;

  public point1Id: string;

  public code0: string;
  public code1: string;

  public isActive: boolean;
    isFloorConnector: boolean;
    displayLabel: string;
}
