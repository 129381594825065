
export class EmployeeData {
  public id: string;
  public code: string;
  public name: string;

  public employeeDesignationId: number;
  public employeeDesignationCode: string;
  public employeeDesignationLabel: string;

  public displayName: string;

  public picture_url: string;
}
