import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStoreManager } from './local-store-manager.service';
import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-Keys';
import { JwtHelper } from './jwt-helper';
import { Utilities } from './utilities';
import { LoginResponse, IdToken } from '../models/login-response.model';
import { FRSHubConnections, User } from '../models/user.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { ResetPassword } from '../models/user-login.model';
import * as coreSignalR from '@aspnet/signalr';
import { connect } from 'tls';

@Injectable()
export class AuthService {

  public get loginUrl() { return this.configurations.loginUrl; }
  public get homeUrl() { return this.configurations.homeUrl; }
  public get registerUrl() { return this.configurations.registerUrl; }
  public get resetPasswordUrl() { return this.configurations.resetPasswordUrl; }

  public registerRedirectUrl: string;
  public loginRedirectUrl: string;
  public logoutRedirectUrl: string;


  public resetUserId = '';
  public resetCode = '';

  public reLoginDelegate: () => void;

  private previousIsLoggedInCheck = false;
  private _loginStatus = new Subject<boolean>();


  constructor(private router: Router, public connections: FRSHubConnections, private configurations: ConfigurationService, private endpointFactory: EndpointFactory, private localStorage: LocalStoreManager) {
    this.initializeLoginStatus();
  }


  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }


  gotoPage(page: string, preserveParams = true) {

    let navigationExtras: NavigationExtras = {
      queryParamsHandling: preserveParams ? "merge" : "", preserveFragment: preserveParams
    };


    this.router.navigate([page], navigationExtras);
  }


  redirectLoginUser() {
    let redirect = this.loginRedirectUrl && this.loginRedirectUrl != '/' && this.loginRedirectUrl != ConfigurationService.defaultHomeUrl ? this.loginRedirectUrl : this.homeUrl;
    this.loginRedirectUrl = null;


    let urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    let urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, '?');

    let navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: "merge"
    };

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }


  redirectLogoutUser() {
    let redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : this.loginUrl;
    this.logoutRedirectUrl = null;

    this.router.navigate([redirect]);
  }


  redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    this.router.navigate([this.loginUrl]);
  }

  redirectForRegister() {
    this.registerRedirectUrl = this.router.url;
    this.router.navigate([this.registerUrl]);
  }

  reLogin() {

    this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);

    if (this.reLoginDelegate) {
      this.reLoginDelegate();
    }
    else {
      this.redirectForLogin();
    }
  }


  refreshLogin() {
    console.log('refreshing login.');
    return this.endpointFactory.getRefreshLoginEndpoint<LoginResponse>().pipe(
      map(response => this.processLoginResponse(response, this.rememberMe, this.institutionCode)));
  }


  login(userName: string, password: string, institutionCode: string, rememberMe?: boolean, isAD?: boolean) {

    if (this.isLoggedIn)
      this.logout();

    return this.endpointFactory.getLoginEndpoint<LoginResponse>(userName, password, institutionCode, 'false', isAD).pipe(
      map(response => this.processLoginResponse(response, rememberMe, institutionCode)));
  }

  loginExternal(userName: string) {

    return this.endpointFactory.getLoginEndpoint<LoginResponse>(userName, null, null, 'true').pipe(
      map(response => this.processLoginResponse(response, false, this.institutionCode)));
  }

  forgotPassword(email: string, institutionCode?: string) {

    if (this.isLoggedIn)
      this.logout();

    return this.endpointFactory.getForgotPasswordEndpoint<any>(email, institutionCode);
  }

  resetPassword(resetPassword: ResetPassword) {

    if (this.isLoggedIn)
      this.logout();

    return this.endpointFactory.getResetPasswordEndpoint<any>(resetPassword);
  }

  private processLoginResponse(response: LoginResponse, rememberMe: boolean, code: string) {

    let accessToken = response.access_token;

    if (accessToken == null)
      throw new Error("Received accessToken was empty");

    let idToken = response.id_token;
    let refreshToken = response.refresh_token || this.refreshToken;
    let expiresIn = response.expires_in;

    let tokenExpiryDate = new Date();
    tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);

    let accessTokenExpiry = tokenExpiryDate;

    //console.log(accessTokenExpiry);
    //console.log(new Date());

    let jwtHelper = new JwtHelper();
    let decodedIdToken = <IdToken>jwtHelper.decodeToken(response.id_token);

    let permissions: PermissionValues[] = Array.isArray(decodedIdToken.permission) ? decodedIdToken.permission : [decodedIdToken.permission];

    if (!this.isLoggedIn)
      this.configurations.import(decodedIdToken.configuration);

    let user = new User(
      decodedIdToken.sub,
      decodedIdToken.name,
      decodedIdToken.fullname,
      decodedIdToken.email,
      decodedIdToken.jobtitle,
      decodedIdToken.phone,
      Array.isArray(decodedIdToken.role) ? decodedIdToken.role : [decodedIdToken.role],
      decodedIdToken.institutionId);
    user.isEnabled = true;

    this.saveUserDetails(user, permissions, accessToken, idToken, refreshToken, accessTokenExpiry, rememberMe, code);

    this.reevaluateLoginStatus(user);

    return user;
  }


  private saveUserDetails(user: User, permissions: PermissionValues[], accessToken: string, idToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean, code?: string) {

    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.savePermanentData(idToken, DBkeys.ID_TOKEN);
      this.localStorage.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
      this.localStorage.savePermanentData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
      this.localStorage.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.savePermanentData(user, DBkeys.CURRENT_USER);
      this.localStorage.saveSyncedSessionData(code, DBkeys.INSTITUTION_CODE);
    }
    else {
      this.localStorage.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(idToken, DBkeys.ID_TOKEN);
      this.localStorage.saveSyncedSessionData(refreshToken, DBkeys.REFRESH_TOKEN);
      this.localStorage.saveSyncedSessionData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
      this.localStorage.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
      this.localStorage.saveSyncedSessionData(code, DBkeys.INSTITUTION_CODE);
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
  }



  logout(): void {
    if (this.connections.userHubConnection != null) {
      this.disconnectSignalRConnection(this.connections.userHubConnection, '');
    }

    this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
    this.localStorage.deleteData(DBkeys.ID_TOKEN);
    this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
    this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(DBkeys.USER_PERMISSIONS);
    this.localStorage.deleteData(DBkeys.CURRENT_USER);
    this.localStorage.deleteData(DBkeys.INSTITUTION_CODE);

    this.configurations.clearLocalChanges();
    //this.reevaluateLoginStatus();

    if (this.connections.sessionInterval) {
      clearInterval(this.connections.sessionInterval);
    }
  }


  private reevaluateLoginStatus(currentUser?: User) {

    let user = currentUser || this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    let isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck != isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }


  getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }


  get currentUser(): User {

    let user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    if (user && !user.institutionId) user.institutionId = '';
    this.reevaluateLoginStatus(user);

    return user;
  }

  get userPermissions(): PermissionValues[] {
    return this.localStorage.getDataObject<PermissionValues[]>(DBkeys.USER_PERMISSIONS) || [];
  }

  get accessToken(): string {

    this.reevaluateLoginStatus();
    return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date {

    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
  }

  get isSessionExpired(): boolean {

    if (this.accessTokenExpiryDate == null) {
      return true;
    }
    //console.log(this.accessTokenExpiryDate);
    //console.log(new Date());
    return !(this.accessTokenExpiryDate.valueOf() > new Date().valueOf());
  }


  get idToken(): string {

    this.reevaluateLoginStatus();
    return this.localStorage.getData(DBkeys.ID_TOKEN);
  }

  get refreshToken(): string {

    this.reevaluateLoginStatus();
    return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) == true;
  }

  get institutionCode(): string {
    return this.localStorage.getData(DBkeys.INSTITUTION_CODE);
  }

  signalRConnection(url: string, skipAuth?: boolean): coreSignalR.HubConnection {
    let connection: coreSignalR.HubConnection;
    console.log(this.connections);
    //if (this.connections.hasOwnProperty(url)) {
    //  connection = this.connections[url];
    //}
    //else {
    if (this.currentUser != null || skipAuth) {
      connection = new coreSignalR.HubConnectionBuilder()
        .withUrl(url)
        .configureLogging(coreSignalR.LogLevel.Debug)
        .configureLogging({
          log: function (logLevel, message) {
            //console.log(logLevel);
            console.log(url + " - " + new Date().toISOString() + ": " + message);
          }
        })
        .build();

      this.connections[url] = connection;
    }
    //}

    connection.keepAliveIntervalInMilliseconds = ConfigurationService.defaultSignalRKeepAliveInterval * 1000 * 60;
    connection.serverTimeoutInMilliseconds = ConfigurationService.defaultSignalRServerTimeout * 1000 * 60;
    this.connections.forceStop = false;
    if (connection.state !== coreSignalR.HubConnectionState.Connected) {
      this.startConnection(url, connection);
    }

    connection.onclose((e) => {
      if (!this.connections.forceStop) {
        //if (e) {
        console.log('FROM ON CLOSE:');
        console.log(e);
        this.connections.forceStop = false;
        this.startConnection(url, connection);
        //}
      }
    });


    return connection;
  }

  startConnection(url: string, connection: coreSignalR.HubConnection) {
    let $this = this;
    try {
      if (connection == null) {
        connection = new coreSignalR.HubConnectionBuilder()
          .withUrl(url)
          .configureLogging(coreSignalR.LogLevel.Debug)
          .build();
      }

      if (connection.state !== coreSignalR.HubConnectionState.Connected) {
        connection.start().catch(err => {
          console.log(err);
          setTimeout(function () { $this.startConnection(url, connection) }, 5000);
        });
      }
    } catch (err) {
      setTimeout(function () { $this.startConnection(url, connection) }, 5000);
    }
  }

  disconnectSignalRConnection(connection: coreSignalR.HubConnection, url: string) {
    this.connections.forceStop = true;
    if (connection != null) {
      connection.off('onclose');
      connection.stop().catch(err => {
        console.log(err);
      });

      if (this.connections.hasOwnProperty(url)) {
        //connection = this.connections[url];
        delete this.connections[url];
      }

    }
  }
}
