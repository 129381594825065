
export class CartonAsset {


  public id: string;
  public code: string;
  public institutionId: string;

  public cartonTypeId: string; 

  public cartonTypeCode: string;

  public bentoAssets: any;

  public date: Date;

  public timeStamp: Date;
}
