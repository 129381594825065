
export class NotificationEvent {

  constructor() {
  }

  public id: string;
  public type: string;
  public title: string;
  public template: string;
}
