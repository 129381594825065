import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: "my-root",
  templateUrl: './root-app.component.html',
  styleUrls: ['./root-app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RootAppComponent {
}
