export enum ModulePath {
  PibDisplay = "/pibdisplay",
  NurseCheckinCounter = "/nursecheckincounter",
  PatientCommunicatorDisplay = "/communicatorpatientdisplay",
  Epaper = "/epaper",
  SignageDisplay = "/signagedisplay",
  MeetingRoom = "/meetingroom"
}

enum DeviceRotation {
  LEFT = "Left",
  RIGHT = "RIGHT",
  NORMAL = "Normal",
  INVERTED = "Inverted"
}
