import { Component, ViewChild, Inject } from '@angular/core';

import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from "../../services/account.service";
import { Permission } from '../../models/permission.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PublicHoliday } from 'src/app/models/public-holiday.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { FileService } from 'src/app/services/file.service';


@Component({
  selector: 'public-holiday-editor',
  templateUrl: './public-holiday-editor.component.html',
  styleUrls: ['./public-holiday-editor.component.css']
})
export class PublicHolidayEditorComponent {

  private isNewPublicHoliday = false;
  private isSaving: boolean;
  private showValidationErrors: boolean = true;
  private editingPublicHolidayName: string;
  private publicHolidayEdit: PublicHoliday = new PublicHoliday();
  private allPermissions: Permission[] = [];
  private selectedValues: { [key: string]: boolean; } = {};
  public fileUploadResponse: { dbPath: '', fileId: null, fileName: '' };
  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;


  @ViewChild('f')
  private form;



  constructor(private alertService: AlertService, private calendarService: CalendarService, private accountService: AccountService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<PublicHolidayEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (typeof (data.publicHoliday) != typeof (undefined)) {
      if (data.publicHoliday.id) {
        this.editPublicHoliday(data.publicHoliday);
      } else {
        this.newPublicHoliday();
      }
    }
  }



  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }


  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage("Saving changes...");
    this.publicHolidayEdit.institutionId = this.accountService.currentUser.institutionId;
    if (this.isNewPublicHoliday) {
      this.calendarService.newPublicHoliday(this.publicHolidayEdit).subscribe(publicHoliday => this.saveSuccessHelper(publicHoliday), error => this.saveFailedHelper(error));
    }
    else {
      this.calendarService.updatePublicHoliday(this.publicHolidayEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
    }
  }




  private saveSuccessHelper(publicHoliday?: PublicHoliday) {
    if (publicHoliday)
      Object.assign(this.publicHolidayEdit, publicHoliday);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewPublicHoliday)
      this.alertService.showMessage("Success", `Public Holiday \"${this.publicHolidayEdit.name}\" was created successfully`, MessageSeverity.success);
    else
      this.alertService.showMessage("Success", `Changes to public holiday \"${this.publicHolidayEdit.name}\" was saved successfully`, MessageSeverity.success);


    this.publicHolidayEdit = new PublicHoliday();
    this.resetForm();


    //if (!this.isNewPublicHoliday && this.accountService.currentUser.facilities.some(r => r == this.editingPublicHolidayName))
    //    this.refreshLoggedInUser();

    if (this.changesSavedCallback)
      this.changesSavedCallback();

    this.dialogRef.close();
  }


  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured while saving your changes:", MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }


  private cancel() {
    this.publicHolidayEdit = new PublicHoliday();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback)
      this.changesCancelledCallback();

    this.dialogRef.close();
  }

  private toggleGroup(groupName: string) {
    let firstMemberValue: boolean;

    this.allPermissions.forEach(p => {
      if (p.groupName != groupName)
        return;

      if (firstMemberValue == null)
        firstMemberValue = this.selectedValues[p.value] == true;

      this.selectedValues[p.value] = !firstMemberValue;
    });
  }


  private getSelectedPermissions() {
    return this.allPermissions.filter(p => this.selectedValues[p.value] == true);
  }


  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }


  newPublicHoliday() {
    this.isNewPublicHoliday = true;
    this.showValidationErrors = true;

    this.editingPublicHolidayName = null;
    this.selectedValues = {};
    this.publicHolidayEdit = new PublicHoliday();

    return this.publicHolidayEdit;
  }

  editPublicHoliday(publicHoliday: PublicHoliday) {
    if (publicHoliday) {
      this.isNewPublicHoliday = false;
      this.showValidationErrors = true;

      this.editingPublicHolidayName = publicHoliday.name;
      this.selectedValues = {};
      this.publicHolidayEdit = new PublicHoliday();
      Object.assign(this.publicHolidayEdit, publicHoliday);

      return this.publicHolidayEdit;
    }
    else {
      return this.newPublicHoliday();
    }
  }


  get canManagePublicHolidays() {
    return true;// this.accountService.userHasPermission(Permission.manageReservationsPermission)
  }
}
