import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { Filter, PagedResult } from '../../models/sieve-filter.model';
import { Permission } from '../../models/permission.model';
import { MatDialog } from '@angular/material';
import { PublicHoliday } from 'src/app/models/public-holiday.model';
import { PublicHolidayEditorComponent } from './public-holiday-editor.component';
import { CalendarService } from 'src/app/services/calendar.service';
import { FileService } from 'src/app/services/file.service';


@Component({
  selector: 'public-holidays-management',
  templateUrl: './public-holidays-management.component.html',
  styleUrls: ['./public-holidays-management.component.css']
})
export class PublicHolidaysManagementComponent implements OnInit {
  columns: any[] = [];
  rows: PublicHoliday[] = [];
  rowsCache: PublicHoliday[] = [];
  allPermissions: Permission[] = [];
  editedPublicHoliday: PublicHoliday;
  sourcePublicHoliday: PublicHoliday;
  editingPublicHolidayName: { name: string };
  loadingIndicator: boolean;
  filter: Filter;
  pagedResult: PagedResult;
  keyword: string = '';

  @ViewChild('iconTemplate')
  iconTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('publicHolidayEditor')
  publicHolidayEditor: PublicHolidayEditorComponent;
  header: string;
  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService,
    private fileService: FileService, private calendarService: CalendarService, public dialog: MatDialog) {
  }

  openDialog(publicHoliday: PublicHoliday): void {
    const dialogRef = this.dialog.open(PublicHolidayEditorComponent, {
      data: { header: this.header, publicHoliday: publicHoliday },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData(null);
    });
  }

  importClicked() {
    document.getElementById('file').click()
  }

  public uploadFinished = (event) => {
    let importFile = event ? event.dbPath : null;

    var r = confirm(`Are you sure you want to import from selected file?\nAny data with same name will be overwritten, and you cannot undo this action.`);
    if (r) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.calendarService.import(importFile)
        .subscribe(results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.loadData();

          this.alertService.showMessage("Import Success", "Import Success", MessageSeverity.success);
        },
          error => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;

            this.alertService.showStickyMessage("Load Error", `Unable to import to the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
              MessageSeverity.error);
          });
    }
  }

  initializeFilter() {
    this.filter = new Filter(1, 10);
    this.filter.sorts = '-date';
    this.filter.filters = '';
    this.filter.page = 1;

    
  }

  initializePagedResult() {
    this.pagedResult = new PagedResult();
    this.pagedResult.totalCount = 0;
    this.pagedResult.pagedData = [];
    this.pagedResult.filter = this.filter;
  }

  initializeTableDefinition() {
    let gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [

      { prop: 'date', name: 'Date', width: 200 },
      { prop: 'name', name: gT('common.Name'), width: 200 },
      { name: '', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
    ];

    if (!this.accountService.currentUser.institutionId || this.accountService.currentUser.institutionId == '0') {
      this.columns.splice(1, 0, { prop: 'institutionName', name: gT('roles.management.Institution'), width: 120 });
    }
  }

  ngOnInit() {
    this.initializeFilter();
    this.initializePagedResult();
    this.initializeTableDefinition();
    this.loadData();
  }


  loadData(ev?: any) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.filter.pageSize = 10;

    if (ev) {
      this.filter.page = ev.offset + 1;
      if (ev.sorts) {
        this.filter.sorts = ev.sorts[0].dir == 'desc' ? '-' + ev.sorts[0].prop : ev.sorts[0].prop;
      }
    }

    if (!this.keyword) this.keyword = '';
    this.filter.filters = '(IsActive)==true,(Name)@=' + this.keyword ;
    
    this.calendarService.getPublicHolidaysByFilter(this.filter)
      .subscribe(results => {
        this.pagedResult = results;

        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        let publicHolidays = results.pagedData;

        publicHolidays.forEach((publicHoliday, index, publicHolidays) => {
          if (publicHoliday.date) {
            publicHoliday.dateObj = new Date(publicHoliday.date);
            publicHoliday.date = publicHoliday.date.split("T")[0];
          }

          (<any>publicHoliday).index = index + 1;
        });


        this.rowsCache = [...publicHolidays];
        this.rows = publicHolidays;

      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Load Error", `Unable to retrieve records from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error);
        });
  }


  onSearchChanged(value: string) {
    //this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.name, r.description));
    this.keyword = value;
    this.loadData(null);
  }


  onEditorModalHidden() {
    this.editingPublicHolidayName = null;
    this.publicHolidayEditor.resetForm(true);
  }


  newPublicHoliday() {
    //this.editingPublicHolidayName = null;
    //this.sourcePublicHoliday = null;
    //this.editedPublicHoliday = this.publicHolidayEditor.newPublicHoliday();
    //this.editorModal.show();
    this.header = 'New Public Holiday';
    this.editedPublicHoliday = new PublicHoliday();
    this.openDialog(this.editedPublicHoliday);
  }


  editPublicHoliday(row: PublicHoliday) {
    //this.editingPublicHolidayName = { name: row.name };
    //this.sourcePublicHoliday = row;
    this.editedPublicHoliday = row; //this.publicHolidayEditor.editPublicHoliday(row);
    //this.editorModal.show();

    this.header = 'Edit Public Holiday';
    this.openDialog(this.editedPublicHoliday);
  }

  deletePublicHoliday(row: PublicHoliday) {
    this.alertService.showDialog('Are you sure you want to delete the \"' + row.name + '\" public holiday?', DialogType.confirm, () => this.deletePublicHolidayHelper(row));
  }


  deletePublicHolidayHelper(row: PublicHoliday) {

    this.alertService.startLoadingMessage("Deleting...");
    this.loadingIndicator = true;

    this.calendarService.deletePublicHoliday(row.id)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        //this.rowsCache = this.rowsCache.filter(item => item !== row)
        //this.rows = this.rows.filter(item => item !== row)
        this.loadData();
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Delete Error", `An error occured while deleting the public holiday.\r\nError: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error);
        });
  }

  getFileImage(path) {
    return this.fileService.getFile(path);
  }

  get canManagePublicHolidays() {
    return true;// this.accountService.userHasPermission(Permission.managePublicHolidaysPermission)
  }

}
