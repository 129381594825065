import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { AccountService } from '../../../services/account.service';
import { Utilities } from '../../../services/utilities';
import { Filter, PagedResult } from '../../../models/sieve-filter.model';
import { Permission } from '../../../models/permission.model';
import { MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { AuditService } from 'src/app/services/audit.service';
import { DateTimeOnlyPipe } from 'src/app/pipes/datetime.pipe';
import { Subscription } from 'rxjs';
import { SearchBoxComponent } from '../../controls/search-box.component';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { TokenOrder } from 'src/app/models/meal-order/token-order.model';
import { LocationService } from '../../../services/location.service';


@Component({
  selector: 'order-logs-management',
  templateUrl: './device-status-reports-management.component.html',
  styleUrls: ['./device-status-reports-management.component.css']
})
export class DeviceStatusReportsManagementComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  columns: any[] = [];
  rows: TokenOrder[] = [];
  rowsCache: TokenOrder[] = [];
  editingAuthLogName: { name: string };
  loadingIndicator: boolean;
  filter: Filter;
  pagedResult: PagedResult;
  keyword: string = '';
  start = new Date();
  end = new Date();

  tstart;// = new Date();
  tend;// = new Date();

  location_id;

  @ViewChild('searchbox') searchbox: SearchBoxComponent;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('mealDescription')
  mealDescriptionTemplate: TemplateRef<any>;

  @ViewChild('orderLogTable') table: any;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService,
    private orderLogService: AuditService, private locationService: LocationService) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  buildingList: Location[];
  private locations: Location[] = [];
  //getRoomsByBuildingId(id) {
  //  let unsortedLocations = this.locations.filter(f => f.parentLocationId == id)
  //  unsortedLocations.sort((a, b) => (a.name < b.name ? -1 : 1));

  //  return unsortedLocations;
  //}

  getLocations() {
    //get locations

    this.subscription.add(this.locationService.getLocationsByUser(this.accountService.currentUser.id, false)
      .subscribe(results => {
        this.locations = results;
        console.log('getFilters');
        //var rooms = results.filter(f => f.locationTypeName == LocType.Room);//.filter(f => f.locationTypeName == LocType.Room);
        //this.rooms = rooms;
        //this.buildingList = [];
        //var bList = {};
        //rooms.forEach(function (loc, i, array) {
        //  if (loc.parentLocation != null && loc.parentLocation.locationTypeName == LocType.Building) {
        //    if (!bList.hasOwnProperty(loc.parentLocation.id)) {
        //      bList[loc.parentLocation.id] = loc.parentLocation;
        //    }
        //  }
        //});

        //var keys = Object.keys(bList);
        //this.buildingList = keys.map(function (v) { return bList[v]; });
      },
        error => {
          this.alertService.showStickyMessage("Get Error", `An error occured while retrieving locations.`,
            MessageSeverity.error);
        }));

    //this.getDeviceTypes();
  }

  initializeFilter() {
    this.filter = new Filter(1, 10);
    this.filter.sorts = '-updatedDate';
    this.filter.filters = '';
    this.filter.page = 1;
  }

  initializePagedResult() {
    this.pagedResult = new PagedResult();
    this.pagedResult.totalCount = 0;
    this.pagedResult.pagedData = [];
    this.pagedResult.filter = this.filter;
  }

  initializeTableDefinition() {
    let gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      //{ prop: "index", name: '#', width: 50, cellTemplate: this.indexTemplate, canAutoResize: false },
      { prop: 'index', name: 'S/N', width: 50 },
      { prop: 'locationName', name: 'Location' },
      { prop: 'device_label', name: 'Label' },
      { prop: 'macAddress', name: 'Mac Address' },
      { prop: 'ipAddress', name: 'Ip Address' },

      { prop: 'connection_status_display', name: 'Status' },
      
    ];
  }

  clearFilterAndPagedResult() {
    this.initializeFilter();
    this.initializePagedResult();
    this.table.offset = 0;
  }

  ngOnInit() {
    this.clearFilters();

    this.initializeFilter();
    this.initializePagedResult();
    this.initializeTableDefinition();
    this.loadData();
    this.getLocations();
  }

  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }


  loadData(ev?: any) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.filter.pageSize = 10;

    if (ev) {
      this.filter.page = ev.offset + 1;
      if (ev.sorts) {
        this.filter.sorts = ev.sorts[0].dir == 'desc' ? '-' + ev.sorts[0].prop : ev.sorts[0].prop;
      }
    }

    if (!this.keyword) this.keyword = '';
    this.filter.filters = '(locationName|device_label|macAddress|ipAddress|rotation|publicationName)@=' + this.keyword + `,(IsActive)==true,UpdatedDate>=${this.start.toDateString()},UpdatedDate<=${this.end.toDateString()},LocationId==${this.location_id || ''}`;
    
    this.orderLogService.getDevicePublicationReportsByFilter(this.filter)
      .subscribe(results => {
        this.pagedResult = results;
        console.log(this.pagedResult);
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        let orderLogs = results.pagedData;

        orderLogs.forEach((orderLog, index, orderLogs) => {
          (<any>orderLog).index = index + 1;

          (<any>orderLog).tat = orderLog.createdDate && orderLog.approvedDate ? this.datediff(new Date(orderLog.createdDate), new Date(orderLog.approvedDate)) || '' : '';
        });


        this.rowsCache = [...orderLogs];
        this.rows = orderLogs;

      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Load Error", `Unable to retrieve order logs from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error);
        });
  }

  onChangeDate(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type == 'start') {
      this.start = new Date(event.value);
    }

    if (type == 'end') {
      this.end = new Date(event.value);
    }

    if (type == 'tstart') {
      this.tstart = new Date(event.value);
    }

    if (type == 'tend') {
      this.tend = new Date(event.value);
    }

    //this.loadData();

    this.loadData();
  }

  onSearchChanged(value: string) {
    //this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.name, r.description));
    this.keyword = value;
    //this.loadData(null);
  }

  onSearch() {
    this.clearFilterAndPagedResult();
    this.loadData(null);
  }

  clearFilters() {
    this.start = new Date();
    this.end = new Date();
    this.end.setDate(this.end.getDate() + 1);
    this.keyword = '';
    this.clearFilterAndPagedResult();
    this.searchbox.clear();
  }

  downloadResults() {
    const fileName = moment().format('DDMMYYYY_hhmmss') + '_Orders.xlsx';
    this.filter.page = null;
    this.filter.pageSize = null;
    this.orderLogService.downloadDeviceStatusReportsReport(this.filter).subscribe(
      data => {
        console.log(data);
        saveAs(data, fileName);
      },
      err => {
        alert("Problem while downloading the file.");
        console.error(err);
      }
    );
  }

  get canManageAuthLogs() {
    return true;// this.accountService.userHasPermission(Permission.viewAuthLogsPermission)
  }

}
