import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { HttpEventType, HttpClient, HttpEvent } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Utilities } from 'src/app/services/utilities';
import { Dish, DishDetail } from 'src/app/models/meal-order/dish.model';
import { DishService } from 'src/app/services/meal-order/dish.service';
import { Filter } from 'src/app/models/sieve-filter.model';
import { ActivatedRoute } from '@angular/router';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'dish-preview',
  templateUrl: './dish-preview.component.html',
  styleUrls: ['./dish-preview.component.css']
})
export class DishPreviewComponent implements OnInit {
  id: string;
  dish: Dish;
  totalWeight: number;

  constructor(private http: HttpClient, private dishService: DishService,
    private fileService: FileService, private route: ActivatedRoute) {
    this.route.params.subscribe(queryParams => {
      this.id = queryParams["id"];
    });
  }

  ngOnInit() {
    if (this.id) {
      this.dishService.getDishById(this.id).subscribe(results => {
        this.dish = results;
        if (this.dish && this.dish.subDishes) {
          this.totalWeight = this.dish.subDishes.filter(item => item.dish.cookedWeight)
            .reduce((sum, current) => sum + current.dish.cookedWeight, 0);
        }
        
      }, error => { });
    }
  }

  getFileImage(path) {
    return this.fileService.getFile(path);
  }
}
