export class PaymentOptions {
  public paymentName: string;
  public paymentCode: string;
  public isEnabled: Boolean;
  public paymentDiscount: number;
  public discountedPaymentAmount: number;
  public imageSrc: string;
}


