
export class Address {
  public city: string;
  public country: string;
  public dependentLocality: string;
  public organization: string;
  public phone: string;
  public postalCode: string;
  public recipient: string;
  public region: string;
  public addressLine: string[];
}
