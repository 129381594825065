import { Component, ViewChild, Input, Inject } from '@angular/core';
import { fadeInOut } from '../../services/animations';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Image } from '../../models/image.model';

import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AccountService } from "../../services/account.service";
import { ReservationService } from '../../services/reservation.service';
import { Reservation, ReservationPicture } from '../../models/reservation.model';
import { Permission } from '../../models/permission.model';
import { Facility } from '../../models/facility.model';
import { Location } from '../../models/location.model';
import { ReservationTime } from '../../models/reservationtime.model';
import { PictureEditorComponent } from './picture-editor/picture-editor.component';

import { Utilities } from 'src/app/services/utilities';
import { FileService } from 'src/app/services/file.service';

import { ImageFileService } from 'src/app/services/image-file.service';

import { MediaService } from '../../services/media.service';
import { Media } from '../../models/media.model';
import { MediaExtensionService } from '../../services/media-extension.service';
import { MediaExtension } from '../../models/media-extension.model';
import { ImageUploadsComponent } from '../imageUpload/image-upload.component';

@Component({
  selector: 'reservation-picture',
  templateUrl: './reservation-picture.component.html',
  styleUrls: ['./reservation-picture.component.css'],
  animations: [fadeInOut]
})
export class ReservationPictureComponent {

  private isEditMode = false;
  private isNewReservation = false;
  private isSaving: boolean;
  private showValidationErrors: boolean = true;
  private editingReservationName: string;
  private reservationEdit: Reservation = new Reservation();
  private allFacilities: Facility[] = [];
  private allLocations: Location[] = [];
  private selectedValues: { [key: string]: boolean; } = {};
  private allStartTimes: ReservationTime[] = [];
  private allEndTimes: ReservationTime[] = [];

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  public disabledTime = false;

  selectedStartTime: string;
  selectedEndTime: string;

  imagePage: Image[] = [];
  imageAll: Image[] = [];

  search: string;
  mediaGroup: string = '';
  sort: string = '';
  fileType: string = '';
  ext: string = '';
  asc: boolean;

  page = 0;
  size = 9;
  length: number;

  allMedias: Media[];
  allMediaExtensions: MediaExtension[];

  @ViewChild('f')
  private form;

  @ViewChild('facilities')
  private facilities;

  @ViewChild('locationsSelector')
  private locationsSelector;

  //@ViewChild('allStartTimesSelector')
  //private allStartTimesSelector;

  //@ViewChild('allEndTimesSelector')
  //private allEndTimesSelector;

  @ViewChild('startTime')
  private startTime;

  @ViewChild('endTime')
  private endTime;

  @Input()
  isViewOnly: boolean;

  constructor(private imageService: ImageFileService, private alertService: AlertService, private accountService: AccountService, private reservationService: ReservationService,
    public dialogRef: MatDialogRef<ReservationPictureComponent>, public dialog: MatDialog, private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: any, private mediaService: MediaService, private mediaExtensionService: MediaExtensionService) {

    this.loadImage();

    this.mediaService.getMedias()
      .subscribe(results => {
        this.allMedias = results;
      },
        error => {
        });

    this.mediaExtensionService.getMediaExtensionByInstitutionId(this.accountService.currentUser.institutionId)
      .subscribe(results => {
        this.allMediaExtensions = results[0];
      },
        error => {

        })
  }

  loadImage() {
    this.imageService.getImages(-1, -1, this.accountService.currentUser.institutionId, '', this.accountService.currentUser.id)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();

        let result_data = results;

        result_data.forEach((image, index, result_data) => {
          (<any>image).index = index + 1;
        });

        this.imageAll = result_data;

        this.refresh();

      },
        error => {
          this.alertService.stopLoadingMessage();

          this.alertService.showStickyMessage("Load Error", `Unable to retrieve images from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error);
        });
  }

  refresh() {
    this.page = 0;
    this.getData();
  }

  newImage() {
    //this.editingDepartmentName = null;
    //this.sourceDepartment = null;
    //this.editedDepartment = this.departmentEditor.newDepartment();
    ////this.editorModal.show();
    //this.header = 'New Department';
    this.openDialog({});
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(ImageUploadsComponent, {
      data: data,
      width: '80vw',
      height: '90vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadImage();
    });
  }


  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }


  private save() {

    if (!this.reservationEdit.isAllDay) {
      //if (this.reservationEdit.startTime == null) {
      //  this.startTime.valid = false;
      //}

      //if (this.reservationEdit.endTime == null) {
      //  this.endTime.valid = false;
      //}

      //if (!this.startTime.valid || !this.endTime.valid) return;
    }

    if (!this.selectedStartTime || !this.selectedEndTime) {
      alert("Please select start and end time");
      return;
    }

    this.isSaving = true;
    this.alertService.startLoadingMessage("Saving changes...");

    //this.reservationEdit.startTime = this.getReservationTimeBy(this.reservationEdit.startTime.id);
    //this.reservationEdit.endTime = this.getReservationTimeBy(this.reservationEdit.endTime.id);
    //var startDateTime = new Date(this.reservationEdit.startDate);

    //startDateTime.setHours(this.reservationEdit.startTime.hour);
    //startDateTime.setMinutes(this.reservationEdit.startTime.minutes);
    //this.reservationEdit.startDateTime = startDateTime;

    //var endDateTime = new Date(this.reservationEdit.endDate);

    //endDateTime.setHours(this.reservationEdit.endTime.hour);
    //endDateTime.setMinutes(this.reservationEdit.endTime.minutes);
    //this.reservationEdit.endDateTime = endDateTime;


    //this.reservationEdit.repeatEndDateTime = new Date(this.reservationEdit.repeatEndDate);

    //var startDateTime = new Date(this.reservationEdit.startDate ? this.reservationEdit.startDate : this.reservationEdit.startDateTime);

    //startDateTime.setHours(this.reservationEdit.startTime.hour);
    //startDateTime.setMinutes(this.reservationEdit.startTime.minute);
    //var startTime = this.selectedStartTime.split(':');
    //var endTime = this.selectedEndTime.split(':');
    //this.reservationEdit.startDateTime = new Date(startDateTime);
    //this.reservationEdit.startDateTime.setHours(parseInt(startTime[0]), parseInt(startTime[1]));


    this.reservationEdit.startDateTime = new Date(this.reservationEdit.startDateTime);
    this.reservationEdit.endDateTime = new Date(this.reservationEdit.endDateTime);

    //this.reservationEdit.endDateTime.setHours(parseInt(endTime[0]), parseInt(endTime[1]));

    if (this.isNewReservation) {
      this.reservationService.newReservation(this.reservationEdit).subscribe(reservation => this.saveSuccessHelper(reservation), error => this.saveFailedHelper(error));
    }
    else {
      this.reservationService.updateReservation(this.reservationEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
    }
  }




  private saveSuccessHelper(reservation?: Reservation) {
    if (reservation) {
      Object.assign(this.reservationEdit, reservation);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewReservation)
      this.alertService.showMessage("Success", `Reservation \"${this.reservationEdit.shortDescription}\" was created successfully`, MessageSeverity.success);
    else
      this.alertService.showMessage("Success", `Changes to reservation \"${this.reservationEdit.shortDescription}\" was saved successfully`, MessageSeverity.success);


    this.reservationEdit = new Reservation();
    this.resetForm();


    //if (!this.isNewReservation && this.accountService.currentUser.reservations.some(r => r == this.editingReservationName))
    //    this.refreshLoggedInUser();

    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }


  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser()
      .subscribe(user => { },
        error => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage("Refresh failed", "An error occured while refreshing logged in user information from the server", MessageSeverity.error);
        });
  }



  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured while saving your changes:", MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }


  private cancel() {
    this.reservationEdit = new Reservation();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback)
      this.changesCancelledCallback();
  }

  private getAllTimes(): ReservationTime[] {
    let times: ReservationTime[] =
      [new ReservationTime(1, "00:00", "00:00", 0, 0),
      new ReservationTime(2, "00:30", "00:29", 0, 29),
      new ReservationTime(3, "01:00", "01:00", 1, 59),
      new ReservationTime(4, "01:30", "02:29", 1, 29),
      new ReservationTime(5, "02:00", "02:00", 2, 59),
      new ReservationTime(6, "02:30", "03:29", 2, 29),
      new ReservationTime(7, "03:00", "03:00", 3, 59),
      new ReservationTime(8, "03:30", "04:29", 3, 29),
      new ReservationTime(9, "04:00", "04:00", 4, 59),
      new ReservationTime(10, "04:30", "05:29", 4, 29),
      new ReservationTime(11, "05:00", "05:00", 5, 59),
      new ReservationTime(12, "05:30", "06:29", 5, 29),
      new ReservationTime(13, "06:00", "06:00", 6, 59),
      new ReservationTime(14, "06:30", "07:29", 6, 29),
      new ReservationTime(15, "07:00", "07:00", 7, 59),
      new ReservationTime(16, "07:30", "08:29", 7, 29),
      new ReservationTime(17, "08:00", "08:00", 8, 59),
      new ReservationTime(18, "08:30", "09:29", 8, 29),
      new ReservationTime(19, "09:00", "09:00", 9, 59),
      new ReservationTime(20, "09:30", "10:29", 9, 29),
      new ReservationTime(21, "10:00", "10:00", 10, 59),
      new ReservationTime(22, "10:30", "11:29", 11, 29),
      new ReservationTime(23, "11:00", "11:00", 10, 59),
      new ReservationTime(24, "11:30", "12:29", 11, 29),
      new ReservationTime(25, "12:00", "12:00", 12, 59),
      new ReservationTime(26, "12:30", "13:29", 12, 29),
      new ReservationTime(27, "13:00", "13:00", 13, 59),
      new ReservationTime(28, "13:30", "14:29", 13, 29),
      new ReservationTime(29, "14:00", "14:00", 14, 59),
      new ReservationTime(29, "14:30", "15:29", 14, 29),
      new ReservationTime(30, "15:00", "15:00", 15, 59),
      new ReservationTime(32, "15:30", "16:29", 15, 29),
      new ReservationTime(33, "16:00", "16:00", 16, 59),
      new ReservationTime(34, "16:30", "17:29", 16, 29),
      new ReservationTime(35, "17:00", "17:00", 17, 59),
      new ReservationTime(36, "17:30", "18:29", 17, 29),
      new ReservationTime(37, "18:00", "18:00", 18, 59),
      new ReservationTime(38, "18:30", "19:29", 18, 29),
      new ReservationTime(39, "19:00", "19:00", 19, 59),
      new ReservationTime(40, "19:30", "20:29", 19, 29),
      new ReservationTime(41, "20:00", "20:00", 20, 59),
      new ReservationTime(42, "20:30", "21:29", 20, 29),
      new ReservationTime(43, "21:00", "21:00", 21, 59),
      new ReservationTime(44, "21:30", "22:29", 21, 29),
      new ReservationTime(45, "22:00", "22:00", 22, 59),
      new ReservationTime(46, "22:30", "23:29", 22, 29),
      new ReservationTime(47, "23:00", "23:00", 22, 59),
      new ReservationTime(48, "23:30", "23:29", 23, 29),
      new ReservationTime(49, "24:00", "23:59", 23, 59)];
    return times;
  }

  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  //public toggleTime() {
  //  this.disabledTime = !this.reservationEdit.isAllDay;
  //  setTimeout(() => this.allStartTimesSelector.refresh());
  //  setTimeout(() => this.allEndTimesSelector.refresh());
  //}
  //public changeEndTime(id) {
  //  if (this.reservationEdit.startTime != null && this.reservationEdit.startTime.id != null && this.reservationEdit.startTime.id.toString() != "") {
  //    var allTimes = this.getAllTimes();
  //    var startTime = this.reservationEdit.startTime;
  //    this.reservationEdit.startTime = this.getReservationTimeBy(this.reservationEdit.startTime.id);
  //    var $this = this;
  //    this.allEndTimes = allTimes.filter(function (p, index, array) {
  //      if (new Date(new Date(p.value).setSeconds(0)) > new Date(startTime.value)) {
  //        p.description = p.description + " (" + $this.getHourDifferenceDescription(p) + " hours)";
  //        return p;
  //      }
  //    });

  //    setTimeout(() => this.allEndTimesSelector.refresh());
  //  }
  //}

  public getHourDifferenceDescription(endTime: ReservationTime): string {
    var diff = new Date(new Date(endTime.value).setSeconds(0)).valueOf() - new Date(new Date(this.reservationEdit.startTime.value).setSeconds(0)).valueOf();
    var diffHours = diff / (1000.0 * 3600.0);

    return diffHours.toFixed(1).toString();
  }

  private getReservationTimeBy(id?: any, hour?: number, minute?: number): ReservationTime {
    var allTimes = this.getAllTimes();
    var time: ReservationTime;
    if (id != null) {
      time = allTimes.filter(f => f.id == id)[0];
    } else {
      var t = allTimes.filter(f => f.hour == hour && f.minutes == minute);
      time = t != null && t.length > 0 ? t[0] : allTimes[0];
    }
    return time;
  }

  newReservation(allLocations: Location[]) {
    this.isEditMode = true;
    this.isNewReservation = true;
    this.showValidationErrors = true;
    this.disabledTime = false;
    this.editingReservationName = null;
    this.allLocations = allLocations;
    this.selectedValues = {};
    this.reservationEdit = new Reservation();
    this.reservationEdit.repeatType = "None";
    this.reservationEdit.startDate = new Date();
    this.reservationEdit.endDate = new Date();

    this.allStartTimes = this.getAllTimes();
    this.reservationEdit.startTime = this.allStartTimes[0];
    this.reservationEdit.endTime = this.allStartTimes[47];
    //this.allEndTimes = this.getAllTimes();
    //setTimeout(() => this.allStartTimesSelector.refresh());
    //setTimeout(() => this.allEndTimesSelector.refresh());
    return this.reservationEdit;
  }

  editReservation(reservation: Reservation, allLocations: Location[]) {
    this.isEditMode = true;
    if (reservation) {
      this.isNewReservation = false;
      this.showValidationErrors = true;
      var allT = this.getAllTimes();
      this.allStartTimes = allT;
      this.allEndTimes = allT;
      //setTimeout(() => this.allStartTimesSelector.refresh());
      this.editingReservationName = reservation.shortDescription;
      this.selectedValues = {};
      this.setReservationTypes(reservation, allLocations);
      this.reservationEdit = new Reservation();
      Object.assign(this.reservationEdit, reservation);
      this.disabledTime = this.reservationEdit.isAllDay;
      this.reservationEdit.repeatEndDate = new Date(reservation.repeatEndDateTime);
      var startTime = new Date(reservation.startDateTime);
      var endTime = new Date(reservation.endDateTime);

      this.reservationEdit.startDate = new Date(startTime);
      this.reservationEdit.endDate = new Date(endTime);

      //this.reservationEdit.startTime = this.getReservationTimeBy(null, startTime.getHours(), startTime.getMinutes());
      //this.reservationEdit.endTime = this.getReservationTimeBy(null, endTime.getHours(), endTime.getMinutes());

      this.selectedStartTime = Utilities.printTimeOnly(this.reservationEdit.startDate ? this.reservationEdit.startDate : this.reservationEdit.startDateTime, true);
      this.selectedEndTime = Utilities.printTimeOnly(this.reservationEdit.endDate ? this.reservationEdit.endDate : this.reservationEdit.endDateTime, true);

      return this.reservationEdit;
    }
    else {
      return this.newReservation(allLocations);
    }
  }

  private setReservationTypes(reservation: Reservation, allLocations?: Location[]) {

    this.allLocations = allLocations ? [...allLocations] : [];

    if (allLocations == null || this.allLocations.length != allLocations.length)
      setTimeout(() => this.locationsSelector.refresh());
  }

  addPicture() {
    var dialog = this.dialog.open(PictureEditorComponent, {
      data: { header: "New Picture",isUserInfo: true },
      width: '600px',
      disableClose: true
    });

    dialog.afterClosed().subscribe(result => {
      console.log("resulting data: ", result)
      if (result && result.pictureUrl) {
        if (typeof (this.reservationEdit) == typeof (undefined)) {
          this.reservationEdit = new Reservation();
        }

        if (typeof (this.reservationEdit.reservationPictures) == typeof (undefined)) {
          this.reservationEdit.reservationPictures = [];
        }

        result.reservationId = this.reservationEdit.id;
        this.reservationEdit.reservationPictures.push(result);
      }
    });
  }

  deletePicture(row: ReservationPicture) {
    this.alertService.showDialog('Are you sure you want to delete this picture ?', DialogType.confirm, () => this.deletePictureHelper(row));
  }

  deletePictureHelper(row: ReservationPicture) {

    this.reservationEdit.reservationPictures = this.reservationEdit.reservationPictures.filter(item => item !== row);
  }

  getFileImage(path) {
    return this.fileService.getFile(path);
  }

  get canManageReservations() {
    return this.accountService.userHasPermission(Permission.manageReservationsPermission)
  }

  getData(obj?) {
    if (obj && !isNaN(obj.pageIndex) && !isNaN(obj.pageSize)) {
      this.page = obj.pageIndex;
      this.size = obj.pageSize;
    }

    let startingIndex = this.page * this.size;
    let endingIndex = startingIndex + this.size;

    this.imagePage = this.imageAll.slice(0);;

    if (this.search)
      this.imagePage = this.imagePage.filter(i => (i.title + ' ' + i.description + ' ' + i.tags).toLowerCase().includes(this.search.toLowerCase()));

    if (this.mediaGroup)
      this.imagePage = this.imagePage.filter(i => i.mediaId == this.mediaGroup);

    if (this.fileType)
      this.imagePage = this.imagePage.filter(i => this.fileType == '1' ? !i.isVideo : (this.fileType == '2' ? i.isVideo : true));

    if (this.ext) {
      this.imagePage = this.imagePage.filter(i => i.imageLocation.endsWith(this.ext));
    }

    if (this.sort)
      this.imagePage = this.imagePage.sort((a, b) => {
        let result = 0;

        if (this.sort == '1') result = 1;
        else if (this.sort == '2') result = b.title.localeCompare(a.title);
        else if (this.sort == '3') result = b.description.localeCompare(a.description);

        if (this.asc) result *= -1;

        return result;
      });

    this.length = this.imagePage.length;

    this.imagePage = this.imagePage.filter((img, i) => {
      return i >= startingIndex && i < endingIndex;
    });
  }

  addImage(image) {

    let pict = new ReservationPicture();
    pict.reservationId = this.reservationEdit.id;
    pict.pictureUrl = image.imageLocation;

    this.reservationEdit.reservationPictures.push(pict);
  }
}
