
export class Floor {
  public id: string;
  public code: string;
  public label: string;

  order: number;
  floorId: string;

  isActive: any;

  map: any;
}
