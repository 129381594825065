
export class StoreInfo {

  public id: string;
  public name: string;
  public address: string;

  public code: string;


  public storeType: string;

  public institutionId: string;


  public catererInfoId: string;


  public catererInfoName: string;

  public outletId: string;

  public status: string;

  public checked: boolean;
}
