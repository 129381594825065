import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { HttpEventType, HttpClient, HttpEvent } from '@angular/common/http';
import { DateAdapter, MatDatepickerInputEvent, MatDialog, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Utilities } from 'src/app/services/utilities';
import { Filter, AssetLabelFilter } from 'src/app/models/sieve-filter.model';
import { CatererInfo, CatererOutlet } from 'src/app/models/meal-order/caterer-info.model';
import { DeliveryService } from 'src/app/services/meal-order/delivery.service';
import { Outlet } from 'src/app/models/meal-order/outlet.model';
import { StoreInfo } from 'src/app/models/meal-order/store-info.model';
import { Asset } from 'src/app/models/asset.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { AssetService } from '../../../../services/asset.service';
import { ApplicationSettingService } from "../../../../services/application-setting.service";

@Component({
  selector: 'asset-label',
  templateUrl: './asset-label.component.html',
  styleUrls: ['./asset-label.component.css']
})
export class AssetLabelComponent implements OnInit {
  stores: StoreInfo[];
  storesCache: StoreInfo[];
  selectedStores: StoreInfo[] = [];
  editOutlet: Outlet;
  selectedCaterers: CatererOutlet[];
  startDate = moment();
  endDate = moment();

  filter: AssetLabelFilter;
  showWarranty: boolean = true;
  showSerial: boolean = true;
  qrUrl: boolean = true;
  urlText: string = "";
  showModelType: boolean = true;

  constructor(private http: HttpClient, private alertService: AlertService, private deliveryService: DeliveryService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AssetLabelComponent>, private assetService: AssetService, private applicationSettingService: ApplicationSettingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (typeof (data.filter) != typeof (undefined)) {
      this.filter = data.filter;
    }
  }

  ngOnInit() {

    this.loadData();
  }

  loadData() {

    this.applicationSettingService.getApplicationSettingByKey(null, 'ASSET_LABEL_URL')
      .subscribe(results => {
        console.log("result setting: ", results);
        this.urlText = results.value;

      },
        error => {
          this.alertService.stopLoadingMessage();
        });

  }

  onChangeDate(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("event value: ", moment(event.value))
    if (type == "start") {
      this.startDate = moment(event.value);
    } else if (type == "end") {
      this.endDate = moment(event.value);
    }
  }

  private cancel() {
    this.dialogRef.close({ isCancel: true });
  }

  onSearchChanged(value: string) {
    this.stores = this.storesCache.filter(r => Utilities.searchArray(value, false, r.name));
  }


  downloadLabel() {
    const fileName = moment().format('DDMMYYYY_hhmmss') + '_AssetLabel.pdf';
    this.filter.page = null;
    this.filter.pageSize = null;
    this.filter.showWarranty = this.showWarranty;
    this.filter.showSerial = this.showSerial;
    this.filter.qrUrl = this.qrUrl;
    this.filter.url = this.urlText;
    this.filter.showModelType = this.showModelType;
    this.assetService.downloadAssetLabel(this.filter).subscribe(
      data => {
        console.log(data);
        saveAs(data, fileName);
      },
      err => {
        alert("Problem while downloading the file.");
        console.error(err);
      }
    );
  }


  //downloadReport() {
  //  const fileName = moment().format('DDMMYYYY_hhmmss') + '_OrderReport.pdf';

  //  var insideFilter = new Filter();

  //  var strStartDate = this.startDate.format().split('T');
  //  var strEndDate = this.endDate.format().split('T');
  //  insideFilter.filters = '(IsActive)==true,(TokenOrderDateRange)==' + strStartDate[0] + '|' + strEndDate[0];
  //  console.log("filters: ", insideFilter.filters)
  //  insideFilter.sorts = "DeliveryDate";

  //  this.menuService.downloadOrderReport(insideFilter).subscribe(
  //    data => {
  //      console.log(data);
  //      saveAs(data, fileName);
  //    },
  //    err => {
  //      alert("Problem while downloading the file.");
  //      console.error(err);
  //    }
  //  );
  //}
}
