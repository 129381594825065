export class ApplicationSetting {

  constructor() {
  }

  public id: string;
  public key: string;
  public value: string;
  public description: string;
  public institutionName: string;
  public institutionId: string;
}
