export enum Gender {
    None,
    Female,
    Male
}

export enum LocType {
  Hospital = "Hospital",
  Building = "Building",
  Level = "Level",
  Room = "Room"
}
