
export class BentoBoxType {

  public id: string;
  public code: string;
  public institutionId: string;

  public length?: number;
  public width?: number;
  public height?: number;

  public catererInfoId?: string;

  public catererInfoName?: string;

  public picture?: string;
}
