import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';



import { CommonEndpoint } from './common-endpoint.service';
import { AccountEndpoint } from './account-endpoint.service';
import { AuthService } from './auth.service';
import { Facility } from '../models/facility.model';
import { FacilityType } from '../models/facility-type.model';
import { Reservation } from '../models/reservation.model';
import { ConfigurationService } from './configuration.service';
import { PublicHoliday } from '../models/public-holiday.model';
import { Filter, PagedResult } from '../models/sieve-filter.model';

export type FacilitiesChangedOperation = "add" | "delete" | "modify";
export type FacilitiesChangedEventArg = { facilities: Facility[] | string[], operation: FacilitiesChangedOperation };

export type FacilityTypesChangedOperation = "add" | "delete" | "modify";
export type FacilityTypesChangedEventArg = { facilityTypes: FacilityType[] | string[], operation: FacilityTypesChangedOperation };

@Injectable()
export class CalendarService {

  private readonly _calendarUrl: string = "/api/calendar";
  get calendarUrl() { return this.configurations.baseUrl + this._calendarUrl; }

  private readonly _publicHolidayUrl: string = "/api/calendar/publicholidays";
  get publicHolidayUrl() { return this.configurations.baseUrl + this._publicHolidayUrl; }

  constructor(private router: Router, private http: HttpClient, private authService: AuthService,
    private accountEndpoint: AccountEndpoint, private commonEndpoint: CommonEndpoint, protected configurations: ConfigurationService) {

  }

  getCalendarEvents(page?: number, pageSize?: number) {

    return forkJoin(
      this.commonEndpoint.getPagedList<Reservation[]>(this.calendarUrl, page, pageSize));
  }

  getPublicHolidayById(publicHolidayId: string) {

    return this.commonEndpoint.getById<any>(this.publicHolidayUrl + '/get', publicHolidayId);
  }

  getPublicHolidaysByFilter(filter: Filter) {
    return this.commonEndpoint.getSieve<PagedResult>(this.publicHolidayUrl + '/sieve/list', filter);
  }

  import(filename: string) {

    return this.commonEndpoint.get(this.publicHolidayUrl + '/import?filename=' + filename);
  }

  updatePublicHoliday(publicHoliday: PublicHoliday) {
    if (publicHoliday.id) {
      return this.commonEndpoint.getUpdateEndpoint(this.publicHolidayUrl, publicHoliday, publicHoliday.id);
    }
  }


  newPublicHoliday(publicHoliday: PublicHoliday) {
    return this.commonEndpoint.getNewEndpoint<PublicHoliday>(this.publicHolidayUrl, publicHoliday);
  }


  deletePublicHoliday(publicHolidayOrPublicHolidayId: string | PublicHoliday): Observable<PublicHoliday> {
    return this.commonEndpoint.getDeleteEndpoint<PublicHoliday>(this.publicHolidayUrl, <string>publicHolidayOrPublicHolidayId);
  }
}
