import { extend } from "jquery";

export class Filter {

  constructor(page?: number, pageSize?: number) {
  }

  public sorts: string;
  public filters: string;
  public page: number;
  public pageSize: number;
}

export class PagedResult {

  constructor() {
  }

  public filter: Filter;
  public totalCount: number;
  public pagedData: any;
}

export class CommonFilter extends Filter {
  constructor(page?: number, pageSize?: number) {
    super(page, pageSize);
  }

  public institutionId: string;
}

export class ServiceContractFilter {
  constructor(includeAssets, filter) {
    this.includeAssets = includeAssets;
    this.filter = filter;
  }

  public includeAssets: boolean;
  public filter: Filter;    
}

export class ClassRosterFilter extends Filter {
  public catererId: string;
  public outletId: string;
}

export class AssetLabelFilter extends Filter {
  public showWarranty: boolean;
  public qrUrl: boolean;
  public url: string;
  public showSerial: boolean;
  public showModelType: boolean;
}

export class RoleReportFilter extends Filter {
  public permissions: string[];
}
